"use client";

import { Command, CommandInput } from "@/components/tailwind/ui/command";

import { useCompletion } from "ai/react";
import { ArrowUp } from "lucide-react";
import { useEditor } from "novel";
import { addAIHighlight } from "novel/extensions";
import { useState } from "react";
import Markdown from "react-markdown";
import { toast } from "sonner";
import { Button } from "../ui/button";
import CrazySpinner from "../ui/icons/crazy-spinner";
import Magic from "../ui/icons/magic";
import { ScrollArea } from "../ui/scroll-area";
import AICompletionCommands from "./ai-completion-command";
import AISelectorCommands from "./ai-selector-commands";
//TODO: I think it makes more sense to create a custom Tiptap extension for this functionality https://tiptap.dev/docs/editor/ai/introduction
import {Bot } from "lucide-react";

interface AISelectorProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export function AISelector({ onOpenChange }: AISelectorProps) {
  const { editor } = useEditor();
  const [inputValue, setInputValue] = useState("");

  const { completion, complete, isLoading } = useCompletion({
    // id: "novel",
    api: "/api/generate",
    onResponse: (response) => {
      if (response.status === 429) {
        toast.error("You have reached your request limit for the day.");
        return;
      }
    },
    onError: (e) => {
      toast.error(e.message);
    },
  });

  const hasCompletion = completion.length > 0;

  // Determine the option based on the completion
  let option = "zap";
  if (completion.includes("#Mentor")) {
    option = "mentorZap";
  } else if (completion.includes("#Friend")) {
    option = "friendZap";
  } else if (completion.includes("#Artist")) {
    option = "artistZap";
  } else if (completion.includes("#Scientist")) {
    option = "scientistZap";
  } else if (completion.includes("#Philosopher")) {
    option = "philosopherZap";
  } else if (completion.includes("#Engineer")) {
    option = "engineerZap";
  } else if (completion.includes("#Entrepreneur")) {
    option = "entrepreneurZap";
  } else if (completion.includes("#Doctor")) {
    option = "doctorZap";
  } else if (completion.includes("#Planner")) {
    option = "plannerZap";
  } else {
    option = "zap";
  }

  return (
    <Command className="w-[350px]">
      {hasCompletion && (
        <div className="flex max-h-[400px]">
          <ScrollArea>
            <div className="prose p-2 px-4 prose-sm dark:prose-invert">
                <Markdown>{completion.replace("#Mentor", "")
                .replace("#Friend", "")
                .replace("#Artist", "")
                .replace("#Scientist", "")
                .replace("#Philosopher", "")
                .replace("#Engineer", "")
                .replace("#Entrepreneur", "")
                .replace("#Doctor", "")
                .replace("#Planner", "")
                }</Markdown>
            </div>
          </ScrollArea>
        </div>
      )}

      {isLoading && (
        <div className="flex h-12 w-full items-center px-4 text-sm font-medium text-muted-foreground text-blue-500 dark:text-slate-200">
          <Bot className="mr-2 h-4 w-4 shrink-0  " />
          Thinking
          <div className="ml-2 mt-1">
            <CrazySpinner />
          </div>
        </div>
      )}
      {!isLoading && (
        <>
          {hasCompletion ? (
            <AICompletionCommands
              onDiscard={() => {
                editor.chain().unsetHighlight().focus().run();
                onOpenChange(false);
              }}
              completion={completion}
            />
          ) : (
            <AISelectorCommands onSelect={(value, option) => complete(value, { body: { option } })} />
          )}
          <div className="relative">
            <CommandInput
              value={inputValue}
              onValueChange={setInputValue}
              // autoFocus
              placeholder={hasCompletion ? "Tell AI What To Do Next" : "Ask AI Anything"}
              onFocus={() => addAIHighlight(editor)}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  if (completion) {
                    complete(completion, {
                      body: { option: option, command: inputValue },
                    }).then(() => setInputValue(""));
                  } else {
                    const slice = editor.state.selection.content();
                    const text = editor.storage.markdown.serializer.serialize(slice.content);

                    complete(text, {
                      body: { option: "zap", command: inputValue },
                    }).then(() => setInputValue(""));
                  }
                }
              }}
            />
          </div>
        </>
      )}
    </Command>
  );
}
